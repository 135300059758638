<template>
    <v-container>
        <ASuccessFour 
            :api="this.api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader
            v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row class="mt-3"
            v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Features Detail
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col 
                                cols="12"
                                sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.features"
                                        dense 
                                        outlined
                                        label="Features name"
                                        disabled>
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-select
                                        :items="statusOptions"
                                        v-model="data.status"
                                        dense 
                                        label="Status"
                                        outlined>
                                    </v-select>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-select
                                        :items="statusOptions"
                                        v-model="data.beta"
                                        dense 
                                        outlined
                                        label="Beta">

                                    </v-select>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        color="primary"
                                        plain
                                        @click="()=>{
                                            this.$router.go(-1);
                                        }">
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>


                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,

},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        statusOptions :[
            {
                text:"Enable",
                value:"1"
            },
            {
                text:"Disabled",
                value:"0"
            },
        ],
        data:null,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getFeature") {
                this.data =resp.data;
            }
            if(resp.class==="updateFeatures") {
                this.api.isSuccesful = true;
                this.api.success = resp.data;
                this.$store.dispatch('fetchFeatures');
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchFeature());
        },
        fetchFeature() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/features/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateApi = this.update();
            this.$api.fetch(updateApi);

        },
        cancelSubmit(){
            this.isPending = false;
        },
        update(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/developer/features/'+this.$router.history.current.params.id;
            tempApi.params = this.data;
            return tempApi;
        },

    }
}
</script>